import React from "react"

// Components
import Layout from "../components/layout"
import Clients from "../components/Clients"
import Footer from "../components/Footer"
import SEO from "./../components/Seo"

// styled components
import { Banner, BannerTextContent, BannerTitle } from "./../styles/home"

const IndexPage = props => {
  return (
    <Layout>
      <SEO title="Careers" pathname={props.location.pathname} />
      <Banner>
        <BannerTextContent>
          <BannerTitle>Careers</BannerTitle>
          We are currently not hiring.
        </BannerTextContent>
      </Banner>
      <Clients />
      <Footer />
    </Layout>
  )
}

export default IndexPage
